<template>
  <v-container class="center mt-10">
    <div>
      <br />
      <br />
      <v-card class="mx-auto px-10 py-5">
        <!-- Login -->
        <v-form ref="loginForm" v-if="!apiKey">
          <h2 class="py-6">Aanmelden</h2>
          <div class="login-form">
            <v-text-field
              label="Wachtwoord"
              v-model="wachtwoord"
              class="pr-4"
              @keydown.enter.prevent="login"
            ></v-text-field>
            <v-btn color="success" dark small @click="login" v-if="!loading">
              Login
              <v-icon right dark>mdi-login</v-icon>
            </v-btn>
          </div>
          <v-label>{{ loginValidationMessage }}</v-label>
        </v-form>

        <!-- Input  -->
        <v-form ref="entryForm" v-if="apiKey" class="px-4 py-5">
          <h2 class="pb-6">Foto's opladen</h2>
          <v-row no-gutters align="center">
            <v-col cols="4">
              <v-select
                id="cmbMaand"
                :items="items"
                v-model="selectedMaand"
                @change="selectMaand"
                :rules="[rules.required]"
                label="Selecteer een maand"
              ></v-select>
            </v-col>
          </v-row>
          <v-row no-gutters align="center">
            <br />
            <v-col cols="4">
              <v-file-input
                show-size
                label="Kies uw afbeeldingen"
                @change="selectFile"
                multiple
              ></v-file-input>
            </v-col>

            <div class="pl-2 d-flex">
              <v-btn color="success" class="mr-4" dark small @click="upload" v-if="!loading">
                Opladen
                <v-icon right dark>mdi-cloud-upload</v-icon>
              </v-btn>
              <div v-if="loading">
                <v-progress-circular
                  indeterminate
                  color="primary"
                ></v-progress-circular>
                <span class="pl-2"> Bezig, gelieve te wachten. </span>
              </div>
            </div>

            <v-col cols="12">
              <div v-if="currentFile">
                <div>
                  <v-progress-linear
                    v-model="progress"
                    color="light-blue"
                    height="25"
                    reactive
                    v-if="loading"
                  >
                    <strong>{{ progressDisplay }} %</strong>
                  </v-progress-linear>
                </div>
              </div>
            </v-col>
          </v-row>
        </v-form>
        <!-- Output - messages and images -->
        <v-alert v-if="message" border="left" color="blue-grey" dark>
          {{ message }}
        </v-alert>

        <v-list v-if="fileInfos.length > 0">
          <v-subheader class="subheader">
            Lijst van afbeeldingen voor de maand
            {{ selectedMaand }}

            <v-dialog
              v-model="dialog"
              persistent
              max-width="290"
              v-if="!loading"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-bind="attrs"
                  v-on="on"
                  id="btnDelete"
                  color="red"
                  dark
                  small
                  v-if="!loading"
                >
                  Verwijder alle foto's van de maand {{ selectedMaand }}
                  <v-icon right dark>mdi-image-remove</v-icon>
                </v-btn>
              </template>
              <v-card>
                <v-card-title class="text-h5">
                  Foto's verwijderen.
                </v-card-title>
                <v-card-text
                  >Bent u zeker dat u alle foto's van de maand
                  {{ selectedMaand }} wilt verwijderen?</v-card-text
                >
                <v-card-actions>
                  <v-spacer></v-spacer>
                  <v-btn
                    color="green darken-1"
                    text
                    @click="deleteFotosByMaand"
                  >
                    Ja
                  </v-btn>
                  <v-btn color="green darken-1" text @click="dialog = false">
                    Nee
                  </v-btn>
                </v-card-actions>
              </v-card>
            </v-dialog>
          </v-subheader>

          <v-row class="px-5">
            <v-col
              v-for="(file, index) in fileInfos"
              :key="index"
              class="d-flex child-flex px-2 py-2"
              cols="1"
            >
              <v-img :src="file" aspect-ratio="1" class="grey lighten-2">
                <template v-slot:placeholder>
                  <v-row
                    class="fill-height ma-0"
                    align="center"
                    justify="center"
                  >
                    <v-progress-circular
                      indeterminate
                      color="grey lighten-5"
                    ></v-progress-circular>
                  </v-row>
                </template>
              </v-img>
            </v-col>
          </v-row>
        </v-list>
      </v-card>
    </div>
  </v-container>
</template>

<style>
.center {
  width: 100%;
}

.login-form {
  display: flex;
  justify-content: center;
  width: 400px;
  align-items: baseline;
}

.inputWrapper {
  width: 300px !important;
}

.subheader {
  display: flex;
  justify-content: space-between;
}
</style>
<script>
import UploadService from "../services/UploadService";
import FotoService from "../services/FotoService";
import LoginService from "../services/LoginService";

export default {
  name: "Januari",
  data() {
    return {
      currentFile: undefined,
      items: [
        "Januari",
        "Februari",
        "Maart",
        "April",
        "Mei",
        "Juni",
        "Juli",
        "Augustus",
        "September",
        "Oktober",
        "November",
        "December",
      ],
      selectedMaand: undefined,
      wachtwoord: undefined,
      progress: 0,
      progressDisplay: 0,
      message: "",
      fileInfos: [],
      loading: false,
      dialog: false,
      apiKey: undefined,
      loginValidationMessage: undefined,
      rules: {
        required: (value) => !!value || "Verplicht.",
      },
    };
  },
  mounted() {
    this.selectMaand();
  },
  methods: {
    selectFile(file) {
      this.progress = 0;
      this.currentFile = file;
    },

    selectMaand() {
      this.clearMessage();

      var fotoService = new FotoService();
      fotoService.getFotosByMaand(this.selectedMaand).then((response) => {
        this.fileInfos = response.data;
      });
    },

    clearMessage(){
      this.message = "";
    },

    upload() {
      this.clearMessage();

      if (!this.$refs.entryForm.validate()) {
        this.message = "Gelieve alle velden in te vullen.";
        return;
      }

      if (!this.currentFile) {
        this.message = "Kies een bestand!";
        return;
      }

      this.loading = true;
      this.message = "";
      var fotoService = new FotoService();
      this.uploadAsBatch(this.currentFile, this.apiKey, this.selectedMaand)
        .then(() => {
          this.message = "Upload succesvol!";
          return fotoService.getFotosByMaand(this.selectedMaand);
        })
        .then((files) => {
          this.fileInfos = files.data;
          this.currentFile = undefined;
        })
        .catch((e) => {
          console.log(e);
          this.progress = 0;
          this.message = "Kon het bestand niet uploaden!";
          this.currentFile = undefined;
        });
    },

    async uploadAsBatch(file, apiKey, maand) {
      let batchNumber = 0;
      let batchSize = 1;
      let totalFiles = file.length;
      let uploadServiceInstance = new UploadService();
      let amountOfBatches = Math.ceil(totalFiles / batchSize);
      let maxAmountOfBatches = 5;
      let currentGroupOfBatches = 1;

      while (batchNumber * batchSize < totalFiles) {
        let uploadTasks = [];

        while (batchNumber < maxAmountOfBatches * currentGroupOfBatches && batchNumber * batchSize < totalFiles){
          let startIndex = batchNumber * batchSize;
          let endIndex = startIndex + batchSize;
          if (endIndex > totalFiles) {
            endIndex == totalFiles;
          }
          let filesToUpload = [];

          for (let i = startIndex; i < endIndex; i++) {
            filesToUpload.push(file[i]);
          }
          let uploadTask = uploadServiceInstance
            .uploadSingleBatch(filesToUpload, apiKey, maand)
            .then(() => this.calculateTotalProgress(amountOfBatches));
          uploadTasks.push(uploadTask);
          batchNumber++;
        }
        await Promise.all(uploadTasks);
        currentGroupOfBatches++;
      }
      this.loading = false;
    },

    calculateTotalProgress(amountOfBatches) {
      let increment = 100 / amountOfBatches;
      this.progress += increment;
      this.progressDisplay = Math.ceil(this.progress);
      if (this.progressDisplay > 100) {
        this.progressDisplay = 100;
      }
    },

    deleteFotosByMaand() {
      this.clearMessage();
      this.dialog = false;
      var fotoService = new FotoService();
      var uploadService = new UploadService();
      uploadService
        .deleteFiles(this.selectedMaand, this.apiKey)
        .then(() =>
          fotoService.getFotosByMaand(this.selectedMaand).then((files) => {
            this.fileInfos = files.data;
          })
        );
    },

    login() {
      this.loginValidationMessage = undefined;
      var loginService = new LoginService();
      loginService
        .login(this.wachtwoord)
        .then((response) => {
          this.apiKey = response.data;
        })
        .catch(() => {
          this.loginValidationMessage = "Foutief wachtwoord. ";
        });
    },
  },
};
</script>
